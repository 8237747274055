import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import localize from '../components/localize';

const JobsPage = () => (
  <>
    <Layout
      current="JOBS"
      dePath="/jobs"
      enPath="/en/jobs"
      disableUp
      disableDown
    >
      <SEO siteTitle="JOBS" />
      <StyledJobsSection className="light">
        <img
          src="https://media.giphy.com/media/pAl7yssJQgM4E/giphy.gif"
          alt="Steve Harvey"
        />
        <p className="big centered">
          404
          <br />
          no jobs
          <br />
          found
        </p>
      </StyledJobsSection>
    </Layout>
  </>
);

const StyledJobsSection = styled.section`
  display: flex;
  height: 90vh;
  width: 100%;
  justify-content: center;
  align-items: center;

  p {
    position: absolute;
  }
`;

export default localize(JobsPage);
